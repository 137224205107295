import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";

const products = [
    {
        id: 1,
        src: "/../images/9.png",
        number: "1117729/10",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "12,5*12,5*29(10) КОРИЧНЕВЫЙ",
        price: "190 ₽",
        link: "/"
    },
    {
        id: 2,
        src: "/../images/10.png",
        number: "1117732/05",
        title: "ЯЩИК ДЛЯ ДЕКОРА С КАНАТОМ",
        description: "25*12*25(10) СИРЕНЕВЫЙ",
        price: "200 ₽",
        link: "/"
    },
    {
        id: 3,
        src: "/../images/11.png",
        number: "1117726/02",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "12*12*23(10) РОЗОВЫЙ",
        price: "160 ₽",
        link: "/"
    },
    {
        id: 4,
        src: "/../images/12_2.png",
        number: "1117725/08",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "25*12*23(10) СЕРЫЙ",
        price: "190 ₽",
        link: "/"
    },
    {
        id: 5,
        src: "/../images/1.jpeg",
        number: "1117736/03",
        title: "ЯЩИК ДЛЯ ДЕКОРА НАБОР 1/3 С КАНАТОМ",
        description: "30*18*35(10,5) БЕЛЫЙ",
        price: "605 ₽",
        link: "/"
    },
    {
        id: 6,
        src: "/../images/3.jpeg",
        number: "1117736/70",
        title: "ЯЩИК ДЛЯ ДЕКОРА НАБОР 1/3 С КАНАТОМ",
        description: "30*18*35(10,5) НАТУРАЛЬНЫЙ",
        price: "605 ₽",
        link: "/"
    },
    {
        id: 7,
        src: "/../images/4.jpeg",
        number: "1117736/76",
        title: "ЯЩИК ДЛЯ ДЕКОРА НАБОР 1/3 С КАНАТОМ",
        description: "30*18*35(10,5) ДУБ",
        price: "605 ₽",
        link: "/"
    },
    {
        id: 8,
        src: "/../images/5.jpeg",
        number: "1117736/05",
        title: "ЯЩИК ДЛЯ ДЕКОРА НАБОР 1/3 С КАНАТОМ ",
        description: "30*18*35(10,5) СИРЕНЕВЫЙ",
        price: "605 ₽",
        link: "/"
    },

];

const Other_products = () => {

    const firstFourProducts = products.slice(4, 8);

    const mediaQuery = window.matchMedia('(max-width: 1280px)');

    const boxRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (boxRef.current) {
                const boxPosition = boxRef.current.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.2; // Adjust this value as needed

                if (boxPosition < screenPosition) {
                    setIsVisible(true);
                    window.removeEventListener('scroll', handleScroll);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='mt-10'>
            <div className="bg_special">
                <div ref={boxRef} className={`${isVisible ? 'animation' : ''}`}>
                    <div className='absolute max-xl:relative inset-0 backdrop-filter px-32 max-xl:px-10'>
                        {/* <h2 className={`text-white text-center pt-10 text-6xl max-xl:text-4xl tracking-wide mb-16 leading-normal title_main`}>Today's Special</h2> */}
                        <div className='grid grid-cols-4 max-xl:grid-cols-1 gap-10 relative mt-8'>

                            {firstFourProducts.map(({ id, src, title, description, number, price, link }) => (
                                <Link to={`/products/${id}`}>
                                    <div className="product">
                                        <div className='bg-[#f7f7f7] rounded-lg mt-3 relative overflow-hidden text-center'>
                                            <div className="recipe_image relative">
                                                <div className="image-overlay"></div>
                                                <img src={src} height="180" className='' alt={title} />
                                            </div>

                                            <div className="actions_products text-center">
                                                <div className='bg-[#f4b94c] text-white rounded-lg p-2 text-sm'>
                                                    <a href="/">Купить сейчас</a>
                                                </div>
                                            </div>

                                            <div className='p-2 mb-5'>
                                                <p className='mt-5 font-semibold'>{title}</p>
                                                <p className='text-[#898484] mt-2'>{number}</p>
                                                <p className='text-[#898484] mt-2'>{description}</p>
                                                <p className='text-[#f4b94c] font-semibold mt-2'>{price}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div className=''>

                            {mediaQuery.matches ?
                                <Link to={`/products`} className="bg-[#f6f7fb] text-[#898484] rounded-lg font-semibold mx-auto flex justify-center mt-10 mb-8 max-xl:mt-5 p-4 px-16 max-xl:px-4 w-1/3">более</Link>
                                :
                                <Link to={`/products`} className="bg-[#f6f7fb] text-[#898484] rounded-lg font-semibold mx-auto flex justify-center mt-10 mb-8 max-xl:mt-5 p-4 px-16 max-xl:px-4 w-1/3">Просмотреть все продукты</Link>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Other_products

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Toolbar, Typography, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link, NavLink } from "react-router-dom";
import { CustomerServiceOutlined } from '@ant-design/icons';
import { Input } from 'antd';
const { Search } = Input;

const pages = [
    {
        title: 'Домашняя',
        href: '/',
    },
    {
        title: 'Продукты',
        href: '/products',
    },
    {
        title: 'контакт',
        href: '/contact',
    },
];

function Navbar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const onSearch = (value, _e, info) => console.log(info?.source, value);

    return (
        <>
            <div className=''>
                <div className='px-28 max-xl:px-10'>
                    <div className='py-2'>
                        <AppBar position="sticky" sx={{
                            backgroundColor: '#ffffff',
                            color: "black",
                            transition: 'background-color 0.3s ease-in-out',
                            boxShadow: 'none',
                            borderRadius: "10px"
                        }}>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters>

                                    {/* small ( mobile ) screen sizes */}

                                    <Typography
                                        variant="h6"
                                        noWrap
                                        component="a"
                                        href="/"
                                        sx={{
                                            mr: 2,
                                            display: { xs: 'none', md: 'flex' },
                                            color: 'inherit',
                                            textDecoration: 'none',
                                        }}
                                        className={`flex items-center d_none pan_logo`}
                                    >
                                        <img src="../images/logo2.png" alt="logo" width="90px" height="50px" className="pan" />
                                        {/* <h2 className='text-xl font-extrabold ml-2 text-[#3c3c3c]'>Pinocchio</h2> */}
                                    </Typography>

                                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleOpenNavMenu}
                                            color="inherit"
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorElNav}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            open={Boolean(anchorElNav)}
                                            onClose={handleCloseNavMenu}
                                            sx={{
                                                display: { xs: 'block', md: 'none' },
                                            }}
                                        >
                                            {/* pages links */}
                                            {pages.map(({ title, title_ar, href }, index) => (
                                                <MenuItem key={index} onClick={handleCloseNavMenu}>
                                                    <NavLink to={href} end className={({ isActive }) => isActive ? "font-medium text-[#565656] active" : "font-medium text-[#565656]"} sx={{ textTransform: "capitalize" }}>{title}</NavLink>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Box>

                                    {/* large screen sizes */}

                                    <Typography
                                        variant="h5"
                                        noWrap
                                        component="a"
                                        href="#app-bar-with-responsive-menu"
                                        sx={{
                                            mr: 2,
                                            display: { xs: 'flex', md: 'none' },
                                            flexGrow: 1,
                                            fontFamily: 'monospace',
                                            fontWeight: 700,
                                            letterSpacing: '.3rem',
                                            color: 'inherit',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <img src="../images/logo2.png" alt="logo" width="80px" height="20px" className="pan" />
                                        {/* <h2 className='text-xl font-extrabold ml-2 text-[#3c3c3c]'>Pinocchio</h2> */}
                                    </Typography>

                                    {/* pages buttons */}
                                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "end" }}>
                                        {pages.map(({ title, title_ar, href }, index) => (
                                            // <Button
                                            //     key={index}
                                            //     onClick={handleCloseNavMenu}
                                            //     className='text-lg text-[#565656] hover:text-[#FE9F10] bg-white'
                                            //     sx={{ my: 2, color: 'black', backgroundColor: "white !important", display: 'block', textTransform: "capitalize" }}
                                            // >
                                            //     {title}
                                            // </Button>

                                            <MenuItem key={index} onClick={handleCloseNavMenu}>
                                                <NavLink to={href} end className={({ isActive }) => isActive ? "font-medium text-[#565656] active" : "font-medium text-[#565656] hover:text-[#f4b94c]"} sx={{ textTransform: "capitalize" }}>{title}</NavLink>
                                            </MenuItem>
                                        ))}
                                    </Box>

                                    {/* search input */}

                                    <Box sx={{ flexGrow: 0 }}>
                                        <Tooltip title="" className='flex items-center ml-4 text-lg text-[#565656] d_none'>
                                            <Search
                                                placeholder="Поиск"
                                                size="large"
                                                allowClear
                                                onSearch={onSearch}
                                                style={{
                                                    width: 220,
                                                }}
                                            />
                                            <a
                                                href="tel:+79588197863"
                                                className='bg-[#8c8c8c] p-2 px-3 rounded-full text-white ml-6'
                                            >
                                                <CustomerServiceOutlined />
                                            </a>
                                        </Tooltip>
                                    </Box>
                                </Toolbar>
                            </Container>
                        </AppBar>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Navbar;

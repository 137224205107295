import './App.css';
import Footer from './Components/Footer';
import Main from './Components/Main';
import Navbar from './Components/Navbar';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Products from './Components/Products';
import Other_products from './Components/Other_products';
import ProductDetails from './Components/ProductDetails';
import All_products from './Components/All_products';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact activeClassName="active" element={
            <>
              <Navbar />
              <Main />
              <Products />
              <Other_products />
              <Footer />
            </>
          } />
          <Route path="/products" element={
            <>
              <Navbar />
              <All_products />
              <Footer />
            </>
          } />
          <Route path="products/:productId" element={
            <>
              <Navbar />
              <ProductDetails />
            </>
          } />

          {/* <Route path="/login" element={
            <>
              <Navbar />
            </>
          } /> */}
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;

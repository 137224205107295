import React from 'react';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { Link } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Main = () => {

    return (
        <>
            <div className={`pl-20 max-xl:pl-10`}>
                <div className={`bg overlay rounded-l-3xl `} style={{ height: "100vh" }}>
                    <div className='relative'>
                        <div className='grid grid-cols-2 max-xl:grid-cols-1 gap-4 px-14 max-xl:px-5 pt-32'>
                            <div className='animation'>
                                <p className='text-[#8c8c8c] text-xl max-lg:text-sm font-medium'><WhatshotIcon /> Горячее предложение на этой неделе</p>
                                <h1 className={`text-white mt-4 text-6xl max-xl:text-3xl tracking-wide mb-16 leading-normal title_main`}>Сделайте свой дом счастливее</h1>
                                <a href="https://wa.me/+79588197863" target="_blank" rel="noopener noreferrer" className='btn_more bg-[#8c8c8c] text-white rounded-lg p-5 px-8 max-xl:p-2 max-xl:p-4'>Купить сейчас</a>
                                <Link to="/products" className='btn_more text-[#8c8c8c] text-white rounded-lg p-5 px-6 d_none ml-6'>Посмотреть больше</Link>
                            </div>
                            <div>
                                <img src="/../images/rose4.png" width={200} height={20} className={`mx-auto absolute inset-x-0 -top-14`} />
                                <img src="/../images/6.png" width="450" className={`mx-auto absolute top-50 animation_right d_none`} />
                                <div className='pan_main'></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <a
                href="https://wa.me/+79588197863"
                target="_blank"
                rel="noopener noreferrer"
                className='fixed bottom-5 left-5 text-[#008069] z-10'
            >
                <WhatsAppIcon sx={{ fontSize: "50px" }} />
            </a>
        </>
    )
}

export default Main
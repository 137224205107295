import React from "react";
import { Link } from 'react-router-dom';
import { FacebookFilled, InstagramOutlined, TwitterOutlined, LinkedinFilled, PhoneOutlined } from '@ant-design/icons';

const Footer = () => {
    return (
        <div className={`mt-8`}>
            <div className='px-32 max-xl:px-10'>
                <div className='grid grid-cols-3 max-xl:grid-cols-1 gap-10 pt-5 pb-4 d_none'>
                    <div>
                        <h3 className="font-bold text-lg mb-4">Контакт</h3>
                        <div className="text-[#898484]">
                            <p className="mt-3"><PhoneOutlined className="text-[#f4b94c]" /> +79588197863</p>
                        </div>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg mb-4">страницы</h3>
                        <div className="text-[#898484]">
                            <p className="mt-2"><Link to="/products">Продукты</Link></p>
                            <p className="mt-2"><Link to="/">Контакт</Link></p>
                        </div>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg mb-4">Центр помощи</h3>
                        <div className="text-[#898484]">
                            <p><Link to="/">Часто задаваемые вопросы</Link></p>
                            <p className="mt-2"><Link to="/">политика конфиденциальности</Link></p>
                        </div>
                    </div>
                    {/* <div>
                        <h3 className="font-bold text-lg mb-4">Download App</h3>
                        <div className="text-[#898484]">
                            <p className="mb-3">Save $3 With App & New User only</p>
                            <div style={{ height: "auto", marginTop: "20", maxWidth: 80, width: "100%" }}>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className={`footer mt-4 py-5 text-[#898484]`}>
                <div className="px-32 max-xl:px-10 flex items-center justify-between">
                    {/* <div className="flex text-lg">
                        <FacebookFilled />
                        <InstagramOutlined className="ml-6" />
                        <TwitterOutlined className="ml-6" />
                        <LinkedinFilled className="ml-6" />
                    </div> */}
                    <div className="text-center mx-auto">
                        <p className="text-sm">авторские права 2023 г. Все права защищены. 
                        разработанный <a className="text-[#f4b94c] ml-1" href="https://www.linkedin.com/in/nouran-ali-1732b8266?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">Nouran Ali</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer

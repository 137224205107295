import React, { useEffect, useRef, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";

const products = [
    {
        id: 1,
        src: "/../images/9.png",
        number: "1117729/10",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "12,5*12,5*29(10) КОРИЧНЕВЫЙ",
        price: "190 ₽",
        link: "/"
    },
    {
        id: 2,
        src: "/../images/10.png",
        number: "1117732/05",
        title: "ЯЩИК ДЛЯ ДЕКОРА С КАНАТОМ",
        description: "25*12*25(10) СИРЕНЕВЫЙ",
        price: "200 ₽",
        link: "/"
    },
    {
        id: 3,
        src: "/../images/11.png",
        number: "1117726/02",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "12*12*23(10) РОЗОВЫЙ",
        price: "160 ₽",
        link: "/"
    },
    {
        id: 4,
        src: "/../images/12.png",
        number: "1117725/08",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "25*12*23(10) СЕРЫЙ",
        price: "190 ₽",
        link: "/"
    },

];

const Products = () => {

    const boxRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (boxRef.current) {
                const boxPosition = boxRef.current.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.2; // Adjust this value as needed

                if (boxPosition < screenPosition) {
                    setIsVisible(true);
                    window.removeEventListener('scroll', handleScroll);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='px-32 max-xl:px-10 mt-10 mb-16'>
            <div className={`flex justify-between ${isVisible ? 'animation' : ''}`} ref={boxRef}>
                <div>
                    <h2 className={`text-[#292930] mt-3 text-4xl max-xl:text-2xl mb-8 title_main`}>Изучите наши продукты</h2>
                </div>
                <div className='flex items-center text-white text-sm'>
                </div>
            </div>

            <div className={`grid grid-cols-4 max-xl:grid-cols-1 gap-4  text-[#292930] font-medium ${isVisible ? 'animation_right' : ''}`} ref={boxRef}>
                {products.map(({ id, src, title, number, price, link }) => (
                    <div className={`Recipes`} key={id}>
                        <Link to={`/products/${id}`} className='flex items-center'>
                            <img src={src} width={100} height={200} className='' />
                            <div className='text-start ml-3'>
                                <h3 className='text-[#222222] font-bold'>{title}</h3>
                                <p className='text-[#666666] text-sm mt-2'>{number}</p>
                            </div>
                        </Link>
                        <div className='flex items-center justify-between mt-5'>
                            <div>
                                <p className='text-[#666666]'>Обычная цена</p>
                                <p>{price}</p>
                            </div>
                            <Link to={`/products/${id}`} className={`bg-[#8c8c8c] text-white text-sm p-3 rounded-full ms-3 arrows`}>
                                <ArrowForwardIcon />
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Products

import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, Image, Tabs } from 'antd';

const products = [
    {
        id: 1,
        src: "/../images/9_2.jpeg",
        number: "1117729/10",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "12,5*12,5*29(10) КОРИЧНЕВЫЙ",
        price: "190 ₽",
        length: "12,5 см",
        width: "12,5 см",
        height: "10 см",
        height_handle: "29 см",
        materials: "дерево, фанера",
        image: "/../images/9_3.jpeg",
        link: "/"
    },
    {
        id: 2,
        src: "/../images/666.jpeg",
        number: "1117732/05",
        title: "ЯЩИК ДЛЯ ДЕКОРА С КАНАТОМ",
        description: "25*12*25(10) СИРЕНЕВЫЙ",
        price: "200 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/10_3.jpeg",
        link: "/"
    },
    {
        id: 3,
        src: "/../images/777.jpeg",
        number: "1117726/02",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "12*12*23(10) РОЗОВЫЙ",
        price: "160 ₽",
        length: "12 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/11_3.jpeg",
        link: "/"
    },
    {
        id: 4,
        src: "/../images/12_2.jpeg",
        number: "1117725/08",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "25*12*23(10) СЕРЫЙ",
        price: "190 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/12_3.jpeg",
        link: "/"
    },
    {
        id: 5,
        src: "/../images/1.jpeg",
        number: "1117736/03",
        title: "ЯЩИК ДЛЯ ДЕКОРА НАБОР 1/3 С КАНАТОМ",
        description: "30*18*35(10,5) БЕЛЫЙ",
        price: "605 ₽",
        length: "12,5 см",
        width: "12,5 см",
        height: "10 см",
        height_handle: "29 см",
        materials: "дерево, фанера",
        image: "/../images/2_3.jpeg",
        elem: {
            one: {
                length: "30 см",
                width: "18 см",
                height: "35 см (10,5 см)",
            },
            two: {
                length: "25,5 см",
                width: "15 см",
                height: "29,5 см (8,5 см)",
            },
            three: {
                length: "20 см",
                width: "12 см",
                height: "23,5 см (7,5 см)",
            }
        },
        link: "/"
    },
    {
        id: 6,
        src: "/../images/3.jpeg",
        number: "1117736/70",
        title: "ЯЩИК ДЛЯ ДЕКОРА НАБОР 1/3 С КАНАТОМ",
        description: "30*18*35(10,5) НАТУРАЛЬНЫЙ",
        price: "605 ₽",
        length: "12,5 см",
        width: "12,5 см",
        height: "10 см",
        height_handle: "29 см",
        materials: "дерево, фанера",
        image: "/../images/3_3.jpeg",
        elem: {
            one: {
                length: "30 см",
                width: "18 см",
                height: "35 см (10,5 см)",
            },
            two: {
                length: "25,5 см",
                width: "15 см",
                height: "29,5 см (8,5 см)",
            },
            three: {
                length: "20 см",
                width: "12 см",
                height: "23,5 см (7,5 см)",
            }
        },
        link: "/"
    },
    {
        id: 7,
        src: "/../images/4.jpeg",
        number: "1117736/76",
        title: "ЯЩИК ДЛЯ ДЕКОРА НАБОР 1/3 С КАНАТОМ",
        description: "30*18*35(10,5) ДУБ",
        price: "605 ₽",
        length: "12,5 см",
        width: "12,5 см",
        height: "10 см",
        height_handle: "29 см",
        materials: "дерево, фанера",
        image: "/../images/4_3.jpeg",
        elem: {
            one: {
                length: "30 см",
                width: "18 см",
                height: "35 см (10,5 см)",
            },
            two: {
                length: "25,5 см",
                width: "15 см",
                height: "29,5 см (8,5 см)",
            },
            three: {
                length: "20 см",
                width: "12 см",
                height: "23,5 см (7,5 см)",
            }
        },
        link: "/"
    },
    {
        id: 8,
        src: "/../images/5.jpeg",
        number: "1117736/05",
        title: "ЯЩИК ДЛЯ ДЕКОРА НАБОР 1/3 С КАНАТОМ ",
        description: "30*18*35(10,5) СИРЕНЕВЫЙ",
        price: "605 ₽",
        image: "/../images/5_3.jpeg",
        elem: {
            one: {
                length: "30 см",
                width: "18 см",
                height: "35 см (10,5 см)",
            },
            two: {
                length: "25,5 см",
                width: "15 см",
                height: "29,5 см (8,5 см)",
            },
            three: {
                length: "20 см",
                width: "12 см",
                height: "23,5 см (7,5 см)",
            }
        },
        link: "/"
    },
    {
        id: 9,
        src: "/../images/6_2.jpeg",
        number: "1117736/08",
        title: "ЯЩИК ДЛЯ ДЕКОРА НАБОР 1/3 С КАНАТОМ",
        description: "30*18*35(10,5) СЕРЫЙ",
        price: "605 ₽",
        image: "/../images/6_3.jpeg",
        elem: {
            one: {
                length: "30 см",
                width: "18 см",
                height: "35 см (10,5 см)",
            },
            two: {
                length: "25,5 см",
                width: "15 см",
                height: "29,5 см (8,5 см)",
            },
            three: {
                length: "20 см",
                width: "12 см",
                height: "23,5 см (7,5 см)",
            }
        },
        link: "/"
    },
    {
        id: 10,
        src: "/../images/13_2.jpeg",
        number: "1117736/15",
        title: "ЯЩИК ДЛЯ ДЕКОРА НАБОР 1/3 С КАНАТОМ",
        description: "30*18*35(10,5) МУСКАТ",
        price: "605 ₽",
        image: "/../images/13_3.jpeg",
        elem: {
            one: {
                length: "30 см",
                width: "18 см",
                height: "35 см (10,5 см)",
            },
            two: {
                length: "25,5 см",
                width: "15 см",
                height: "29,5 см (8,5 см)",
            },
            three: {
                length: "20 см",
                width: "12 см",
                height: "23,5 см (7,5 см)",
            }
        },
        link: "/"
    },
    {
        id: 11,
        src: "/../images/14_2.jpeg",
        number: "1117736/77",
        title: "ЯЩИК ДЛЯ ДЕКОРА НАБОР 1/3 С КАНАТОМ",
        description: "30*18*35(10,5) ОРЕХ",
        price: "605 ₽",
        image: "/../images/14_3.jpeg",
        elem: {
            one: {
                length: "30 см",
                width: "18 см",
                height: "35 см (10,5 см)",
            },
            two: {
                length: "25,5 см",
                width: "15 см",
                height: "29,5 см (8,5 см)",
            },
            three: {
                length: "20 см",
                width: "12 см",
                height: "23,5 см (7,5 см)",
            }
        },
        link: "/"
    },
    {
        id: 12,
        src: "/../images/15_2.jpeg",
        number: "1117732/08",
        title: "ЯЩИК ДЛЯ ДЕКОРА С КАНАТОМ",
        description: "25*12*23(10) СЕРЫЙ",
        price: "200 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/15_3.jpeg",
        link: "/"
    },
    {
        id: 13,
        src: "/../images/16_2.jpeg",
        number: "1117732/03",
        title: "ЯЩИК ДЛЯ ДЕКОРА С КАНАТОМ",
        description: "25*12*23(10) БЕЛЫЙ",
        price: "200 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/16_3.jpeg",
        link: "/"
    },
    {
        id: 14,
        src: "/../images/17_2.jpeg",
        number: "1117732/70",
        title: "ЯЩИК ДЛЯ ДЕКОРА С КАНАТОМ",
        description: "25*12*23(10) НАТУРАЛЬНЫЙ",
        price: "200 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/17_3.jpeg",
        link: "/"
    },
    {
        id: 15,
        src: "/../images/18_2.jpeg",
        number: "1117732/02",
        title: "ЯЩИК ДЛЯ ДЕКОРА С КАНАТОМ",
        description: "25*12*23(10) РОЗОВЫЙ",
        price: "200 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/18_3.jpeg",
        link: "/"
    },
    {
        id: 16,
        src: "/../images/19_2.jpeg",
        number: "1117732/10",
        title: "ЯЩИК ДЛЯ ДЕКОРА С КАНАТОМ",
        description: "25*12*23(10) КОРИЧНЕВЫЙ",
        price: "200 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/19_3.jpeg",
        link: "/"
    },
    {
        id: 17,
        src: "/../images/20_2.jpeg",
        number: "1117732/71",
        title: "ЯЩИК ДЛЯ ДЕКОРА С КАНАТОМ",
        description: "25*12*23(10) НАТУРАЛЬНЫЙ-ТЕМНЫЙ",
        price: "200 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/20_3.jpeg",
        link: "/"
    },
    {
        id: 18,
        src: "/../images/21_2.jpeg",
        number: "1117729/03",
        title: "ЯЩИК ДЛЯ ДЕКОРА С КАНАТОМ",
        description: "12,5*12,5*29 (10) БЕЛЫЙ",
        price: "190 ₽",
        length: "12,5 см",
        width: "12,5 см",
        height: "10 см",
        height_handle: "29 см",
        materials: "дерево, фанера",
        image: "/../images/21_3.jpeg",
        link: "/"
    },
    {
        id: 19,
        src: "/../images/22_2.jpeg",
        number: "1117726/77",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "12*12*25(10) ОРЕХ",
        price: "160 ₽",
        length: "12 см",
        width: "12 см",
        height: "10 см",
        height_handle: "25 см",
        materials: "дерево, фанера",
        image: "/../images/22_3.jpeg",
        link: "/"
    },
    {
        id: 20,
        src: "/../images/23_2.jpeg",
        number: "1117726/03",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "12*12*23(10) БЕЛЫЙ",
        price: "160 ₽",
        length: "12 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/23_3.jpeg",
        link: "/"
    },
    {
        id: 21,
        src: "/../images/24_2.jpeg",
        number: "1117726/08",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "12*12*23(10) СЕРЫЙ",
        price: "160 ₽",
        length: "12 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/24_3.jpeg",
        link: "/"
    },
    {
        id: 22,
        src: "/../images/25_2.jpeg",
        number: "1117726/70",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "12*12*23(10) НАТУРАЛЬНЫЙ",
        price: "160 ₽",
        length: "12 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/25_3.jpeg",
        link: "/"
    },
    {
        id: 23,
        src: "/../images/26_2.jpeg",
        number: "1117725/76",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "25*12*23(10) ДУБ",
        price: "190 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/26_3.jpeg",
        link: "/"
    },
    {
        id: 24,
        src: "/../images/27_2.jpeg",
        number: "1117725/02",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "25*12*23(10) РОЗОВЫЙ",
        price: "190 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/27_3.jpeg",
        link: "/"
    },
    {
        id: 24,
        src: "/../images/28_2.jpeg",
        number: "1117725/05",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "25*12*23(10) СИРЕНЕВЫЙ",
        price: "190 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/28_3.jpeg",
        link: "/"
    },
    {
        id: 25,
        src: "/../images/29_2.jpeg",
        number: "1117725/03",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "25*12*23(10) БЕЛЫЙ",
        price: "190 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "23 см",
        materials: "дерево, фанера",
        image: "/../images/29_3.jpeg",
        link: "/"
    },
    {
        id: 26,
        src: "/../images/30_2.jpeg",
        number: "1117725/70",
        title: "ЯЩИК ДЛЯ ДЕКОРА С РУЧКОЙ",
        description: "25*12*25(10) НАТУРАЛЬНЫЙ",
        price: "190 ₽",
        length: "25 см",
        width: "12 см",
        height: "10 см",
        height_handle: "25 см",
        materials: "дерево, фанера",
        image: "/../images/30_3.jpeg",
        link: "/"
    },
];

const ProductDetails = () => {
    const { productId } = useParams();
    console.log(productId);

    const prodId = parseInt(productId);
    const product = products.find(product => product.id === prodId);

    if (!product) {
        return <div>Товар не найден!</div>;
    }

    window.scrollTo(0, 0);

    const mediaQuery = window.matchMedia('(max-width: 1280px)');

    const onChange = (key) => {
        console.log(key);
    };

    const items = [
        {
            key: '1',
            label: 'Обзор',
            children: <div className="text-lg">
                {
                    product.elem ? <div>
                        <h3>Большой ящик:</h3>
                        <p>Длина: <span className="ml-2 text-[#666666]">{product.elem.one.length}</span></p>
                        <p>Ширина: <span className="ml-2 text-[#666666]">{product.elem.one.width}</span></p>
                        <p>Высота: <span className="ml-2 text-[#666666]">{product.elem.one.height}</span></p>

                        <h3 className="mt-3">Средний ящик:</h3>
                        <p>Длина: <span className="ml-2 text-[#666666]">{product.elem.two.length}</span></p>
                        <p>Ширина: <span className="ml-2 text-[#666666]">{product.elem.two.width}</span></p>
                        <p>Высота: <span className="ml-2 text-[#666666]">{product.elem.two.height}</span></p>

                        <h3 className="mt-3">Маленький ящик:</h3>
                        <p>Длина: <span className="ml-2 text-[#666666]">{product.elem.two.length}</span></p>
                        <p>Ширина: <span className="ml-2 text-[#666666]">{product.elem.two.width}</span></p>
                        <p>Высота: <span className="ml-2 text-[#666666]">{product.elem.two.height}</span></p>
                    </div>
                        :
                        <>
                            <p>Длина: <span className="ml-2 text-[#666666]">{product.length}</span></p>
                            <p>Ширина: <span className="ml-2 text-[#666666]">{product.width}</span></p>
                            <p>Высота: <span className="ml-2 text-[#666666]">{product.height}</span></p>
                            <p>Высота с ручкой: <span className="ml-2 text-[#666666]">{product.height_handle}</span></p>
                            <p>Материал: <span className="ml-2 text-[#666666]">{product.materials}</span></p>
                        </>
                }
            </div>
        },
        {
            key: '2',
            label: 'Характеристики',
            children: <img width="500px" src={product.image} />,
        }
    ];

    return (
        <div>
            <div className="bg-[#f8f8f8] py-14">
                <div className="px-28 max-xl:px-10 text-center">
                    <h2 className="text-3xl animation_right">Информация о продукте</h2>
                    <div className="mx-auto flex justify-center animation">
                        <Breadcrumb
                            separator=">"
                            items={[
                                {
                                    title: 'продукты',
                                    href: '/products',
                                },
                                {
                                    title: 'информация о продукте',
                                }
                            ]}
                            className="bg-[#f4b94c] rounded-lg text-white p-3 px-5 mt-6 mx-auto"
                        />
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-4 flex items-center px-28 max-xl:px-10 mt-10">
                <div className="ml-auto mr-5 animation">
                    <Image width={mediaQuery.matches ? 300 : 400} src={product.src} className="rounded-lg mr-5" />
                </div>
                <div className="text-lg text-[#666666] ml-5 animation_right">
                    <p className="">{product.number}</p>
                    <h3 className="text-[#222222] font-bold text-xl mt-2">{product.title}</h3>
                    <p className="mt-5">{product.description}</p>

                    <p className='text-[#666666] mt-5'>Обычная цена</p>
                    <p className="text-[#f4b94c] font-bold mb-10 mt-2">{product.price}</p>

                    <a href="https://wa.me/+79588197863" target="_blank" rel="noopener noreferrer" className="bg-[#f4b94c] text-white p-3 px-5 rounded-lg">Купить сейчас</a>
                </div>
            </div>

            <div className="px-28 max-xl:px-10 mt-10 mb-10">
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
        </div>
    )
}

export default ProductDetails
